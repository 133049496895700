import PropTypes from 'prop-types';

export const saveMetadata = PropTypes.shape({
  // incomplete, varies by usage
  fieldName: PropTypes.string,
  stringVal: PropTypes.string,
  numberVal: PropTypes.number,
});

// routerMatch represents the match prop attached by react-router.
export const routerMatch = PropTypes.shape({
  url: PropTypes.string,
  path: PropTypes.string,
  params: PropTypes.objectOf(PropTypes.string),
  isExact: PropTypes.bool,
});

// history represents the history prop attached by react-router.
// see https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/history.md
export const history = PropTypes.shape({
  length: PropTypes.number,
  action: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.shape({}),
  }),
  push: PropTypes.func,
  replace: PropTypes.func,
  go: PropTypes.func,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  block: PropTypes.func,
});

// provider represents edi.Provider in Go.
export const provider = PropTypes.shape({
  firstName: PropTypes.string,
  lastNameOrOrg: PropTypes.string,
  npi: PropTypes.string,
  ein: PropTypes.string,
  ssn: PropTypes.string,
  comNum: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
});

// person represents edi.Person in Go.
export const person = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  memberID: PropTypes.string,
  ssn: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  gender: PropTypes.string,
  dateOfBirth: PropTypes.string,
});

// professional represents edi.Professional
export const professional = PropTypes.shape({
  workRVU: PropTypes.number,
  workGPCI: PropTypes.number,
  nonFacPERVU: PropTypes.number,
  peGPCI: PropTypes.number,
  mpRVU: PropTypes.number,
  mpGPCI: PropTypes.number,
  cf: PropTypes.number,
});

// outpatient represents edi.outpatient in Go.
export const outpatient = PropTypes.shape({
  cf: PropTypes.number,
  apcRelWeight: PropTypes.number,
  laborPct: PropTypes.number,
  wageIndex: PropTypes.number,
  ppsAdj: PropTypes.number,
});

// cmsRate represents edi.CMSRate in Go.
export const cmsRate = PropTypes.shape({
  rate: PropTypes.number,
});

// labs represents edi.labs in Go.
export const labs = PropTypes.shape({
  allowedVal: PropTypes.number,
});

// drugs represents edi.drugs in Go.
export const drugs = PropTypes.shape({
  pmtLimit: PropTypes.number,
  adjPct: PropTypes.number,
});

// anesthesia represents edi.anesthesia in Go.
export const anesthesia = PropTypes.shape({
  timeUnits: PropTypes.number,
  baseUnits: PropTypes.number,
  cf: PropTypes.number,
});

// inpatient represents edi.inpatient in Go.
export const inpatient = PropTypes.shape({
  baseRate: PropTypes.number,
  wageIndex: PropTypes.number,
  laborPct: PropTypes.number,
  msDRGWgt: PropTypes.number,
});

// dialysis represents edi.dialysis in Go.
export const dialysis = PropTypes.shape({
  baseRate: PropTypes.number,
  laborPct: PropTypes.number,
  wageIndex: PropTypes.number,
  ppsAdj: PropTypes.number,
  hiCostOtlr: PropTypes.number,
  selfDiaAdd: PropTypes.number,
});

// ambulance represents edi.ambulance in Go.
export const ambulance = PropTypes.shape({
  rvu: PropTypes.number,
  cf: PropTypes.number,
  laborPct: PropTypes.number,
  peGPCI: PropTypes.number,
  mileage: PropTypes.number,
  mileRate: PropTypes.number,
});

// ambulatorySurgery represents edi.ambulatorySurgery in Go.
export const ambulatorySurgery = PropTypes.shape({
  cf: PropTypes.number,
  apcRelWeight: PropTypes.number,
  laborPct: PropTypes.number,
  wageIndex: PropTypes.number,
});

// psychiatric represents edi.psychiatric in Go.
export const psychiatric = PropTypes.shape({});

// skilledNursing represents edi.skilledNursing in Go.
export const skilledNursing = PropTypes.shape({
  baseRate: PropTypes.number,
  laborPct: PropTypes.number,
  wageIndex: PropTypes.number,
  rugWeight: PropTypes.number,
});

// hospice represents edi.hospice in Go.
export const hospice = PropTypes.shape({
  baseRate: PropTypes.number,
  laborPct: PropTypes.number,
  wageIndex: PropTypes.number,
});

// alternateReference represents edi.AlternateReference in Go.
export const alternateReference = PropTypes.shape({
  ucrRate: PropTypes.number,
  unit: PropTypes.number,
});

// externalComment represents edi.ExternalComment in Go.
export const externalComment = PropTypes.shape({
  code: PropTypes.string,
  comment: PropTypes.string,
});

// claim form files
export const claimFile = PropTypes.shape({
  StorageFilename: PropTypes.string,
  OriginalFilename: PropTypes.string,
});

// validation represents validation information added by JS.
export const validation = PropTypes.shape({
  error: PropTypes.string,
  errorShort: PropTypes.string,
  warning: PropTypes.string,
  warningShort: PropTypes.string,
  showValidation: PropTypes.bool,
});

// allValidation represents validation information added by JS.
export const allValidation = PropTypes.shape({
  error: PropTypes.string,
  errorShort: PropTypes.string,
  svcHasError: PropTypes.bool,
});

// svcValidation represents validation information added by JS.
export const svcValidation = PropTypes.shape({
  hasError: PropTypes.bool,
});

// repricing represents edi.Repricing in Go.
export const repricing = PropTypes.shape({
  formulaType: PropTypes.string,
  professional,
  outpatient,
  labs,
  drugs,
  anesthesia,
  inpatient,
  cmsRate,
  dialysis,
  ambulance,
  ambulatorySurgery,
  psychiatric,
  skilledNursing,
  hospice,
  alternateReference,
  repricingMethodology: PropTypes.string,
  planMult: PropTypes.number,
  contractFlatRate: PropTypes.number,
  contractPctBill: PropTypes.number,
  contractPctCMS: PropTypes.number,
  intCmnt: PropTypes.string,
  extCmnt: externalComment,
  total: PropTypes.number,
});

// adjudication represents edi.Adjudication in Go.
export const adjudication = PropTypes.shape({
  category: PropTypes.string,
  determination: PropTypes.string,
  shareAmt: PropTypes.number,
});

// serviceItem represents edi.ServiceItem in Go.
export const serviceItem = PropTypes.shape({
  revCode: PropTypes.string,
  placeOfSvc: PropTypes.string,
  procQual: PropTypes.string,
  procCode: PropTypes.string,
  procMod: PropTypes.arrayOf(PropTypes.string),
  procModString: PropTypes.string,
  procDesc: PropTypes.string,
  serviceFromDate: PropTypes.string,
  serviceToDate: PropTypes.string,
  serviceLocation: provider,
  diagnosisCodePointers: PropTypes.arrayOf(PropTypes.number),
  quantity: PropTypes.number,
  billedCharge: PropTypes.number,
  repricing,
  // adjudication,
  validation, // inserted by JS
  allValidation, // inserted by JS
});

export const sbi = PropTypes.shape({
  sbiCode: PropTypes.string.isRequired,
  sbiReason: PropTypes.string.isRequired,
});

// simpleClaim represents edi.SimpleClaim in Go.
export const simpleClaim = PropTypes.shape({
  billProv: provider,
  serviceLocation: provider,
  subscriber: person,
  payorID: PropTypes.string,
  policyNum: PropTypes.string,
  patient: person,
  formType: PropTypes.string,
  typeOfBill: PropTypes.string,
  refNum: PropTypes.string,
  claimNum: PropTypes.string,
  billAmt: PropTypes.number,
  principalDiagnoses: PropTypes.arrayOf(PropTypes.string),
  diagnoses: PropTypes.arrayOf(PropTypes.string),
  admittingDiagnoses: PropTypes.arrayOf(PropTypes.string),
  externalCauseOfInjury: PropTypes.arrayOf(PropTypes.string),
  principalProcedureCodes: PropTypes.arrayOf(PropTypes.string),
  otherProcedureCodes: PropTypes.arrayOf(PropTypes.string),
  drg: PropTypes.string,
  patientCntrlNum: PropTypes.string,
  sbi,
  patientHeight: PropTypes.string,
  patientWeight: PropTypes.string,
  dischargeStatus: PropTypes.string,
  statementFromDate: PropTypes.string,
  statementToDate: PropTypes.string,
  admitDate: PropTypes.string,
  dischargeDate: PropTypes.string,
  services: PropTypes.arrayOf(serviceItem),
  score: PropTypes.string,
  intCmnt: PropTypes.string,
  extCmnt: externalComment,
  extStatusNote: externalComment,
  repriceTotal: PropTypes.number,
  claimFile: PropTypes.object,
  // adjudication,
});

// batch represents edi.Batch in Go.
export const batch = PropTypes.shape({
  batchID: PropTypes.string,
  direction: PropTypes.string,
  date: PropTypes.string,
  filename: PropTypes.string,
});

// claimSummary represents edi.ClaimSummary in Go.
export const claimSummary = PropTypes.shape({
  billedAmt: PropTypes.number,
  billName: PropTypes.string,
  billLoc: PropTypes.string,
  planIDs: PropTypes.arrayOf(PropTypes.string),
  claimNums: PropTypes.arrayOf(PropTypes.string),
  refNums: PropTypes.arrayOf(PropTypes.string),
  claimTypes: PropTypes.arrayOf(PropTypes.string),
  patients: PropTypes.arrayOf(PropTypes.string),
  subscribers: PropTypes.arrayOf(PropTypes.string),
  earliestDt: PropTypes.string,
});

// uiUser represents edi.UIUser in Go.
export const uiUser = PropTypes.shape({
  id: PropTypes.string,
  fullName: PropTypes.string,
});

// handlers represents edi.Handlers in Go.
export const handlers = PropTypes.shape({
  researcher: uiUser,
  repricer: uiUser,
  clinician: uiUser,
  verifier: uiUser,
  dataEnterer: uiUser,
  entryValidator: uiUser,
  // adjudicator: uiUser,
  // adjudicateVerifier: uiUser,
});

// claim represents edi.Claim in Go with some fields inserted by JS.
export const claim = PropTypes.shape({
  id: PropTypes.string,
  simpleClaim,
  delimiters: PropTypes.string,
  senderName: PropTypes.string,
  sixDegTPAID: PropTypes.number,
  x12DocType: PropTypes.string,
  prodOrTest: PropTypes.string,
  completeTime: PropTypes.string,
  billedAmt: PropTypes.number,
  billName: PropTypes.string,
  billLoc: PropTypes.string,
  planIDs: PropTypes.arrayOf(PropTypes.string),
  claimNums: PropTypes.arrayOf(PropTypes.string),
  refNums: PropTypes.arrayOf(PropTypes.string),
  claimTypes: PropTypes.arrayOf(PropTypes.string),
  patients: PropTypes.arrayOf(PropTypes.string),
  subscribers: PropTypes.arrayOf(PropTypes.string),
  earliestDt: PropTypes.string,
  summary: claimSummary,
  status: PropTypes.string,
  payStatus: PropTypes.string,
  batches: PropTypes.arrayOf(batch),
  owner: uiUser,
  handlers,
  alerts: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))),
  originalValues: PropTypes.objectOf(PropTypes.string),
  hierarchy: PropTypes.arrayOf(PropTypes.object),
  validation, // inserted by JS
  allValidation, // inserted by JS
  svcValidation, // inserted by JS
  planName: PropTypes.string, // inserted by JS
  extCmnt: externalComment,
});

// file represents edi.UIFile in Go.
export const file = PropTypes.shape({
  id: PropTypes.string,
  filename: PropTypes.string,
  date: PropTypes.string,
  direction: PropTypes.string,
  contents: PropTypes.string,
  error: PropTypes.string,
  warning: PropTypes.string,
  clientName: PropTypes.string,
  claimIDs: PropTypes.arrayOf(PropTypes.string),
});

// report represents reports.Report in Go
export const reportRow = PropTypes.shape({
  id: PropTypes.string,
  completeTime: PropTypes.string,
  billedAmt: PropTypes.number,
  providerName: PropTypes.string,
  serviceLocationName: PropTypes.string,
  location: PropTypes.string,
  zip: PropTypes.string,
  tpa: PropTypes.string,
  planIDs: PropTypes.arrayOf(PropTypes.string),
  claimNums: PropTypes.arrayOf(PropTypes.string),
  refNums: PropTypes.arrayOf(PropTypes.string),
  claimTypes: PropTypes.arrayOf(PropTypes.string),
  patients: PropTypes.arrayOf(PropTypes.string),
  subscribers: PropTypes.arrayOf(PropTypes.string),
  earliestDt: PropTypes.string,
  status: PropTypes.string,
  payStatus: PropTypes.string,
  reprice: PropTypes.number,
  principalDiagnosis: PropTypes.string,
  comment: PropTypes.string,
  dateReceived: PropTypes.string,
  extStatusNote: PropTypes.shape({
    code: PropTypes.string,
    comment: PropTypes.string,
  }),
  incomingFilename: PropTypes.string,
  exclusions: PropTypes.arrayOf(PropTypes.string),
  possibleExclusions: PropTypes.arrayOf(PropTypes.string),
  extRepricingNotes: PropTypes.arrayOf(PropTypes.string),
  invoiceAmt: PropTypes.number,
});

// duplicate represents duplicate in Go.
export const duplicate = PropTypes.shape({
  ids: PropTypes.arrayOf(PropTypes.string),
  tpa: PropTypes.string,
  planIDs: PropTypes.arrayOf(PropTypes.string),
  billedAmt: PropTypes.number,
  subscriber: PropTypes.arrayOf(PropTypes.string),
  patient: PropTypes.arrayOf(PropTypes.string),
  statementDate: PropTypes.string,
  claimType: PropTypes.arrayOf(PropTypes.string),
});

// dupe represents dupe in Go.
export const dupe = PropTypes.shape({
  matchID: PropTypes.string,
  matchClaimNumber: PropTypes.string,
  matchReferenceNumber: PropTypes.string,
  matchDate: PropTypes.string,
  matchFraction: PropTypes.number,
});

// patientEligibility represents patientEligibility in Go (returned by /api/report-patients).
export const patientEligibility = PropTypes.shape({
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  DateOfBirth: PropTypes.string,
  PlanName: PropTypes.string,
  PlanID: PropTypes.string,
  TPAName: PropTypes.string,
  PatientID: PropTypes.string,
  SubscriberID: PropTypes.string,
  StartDate: PropTypes.string,
  EndDate: PropTypes.string,
  CensusDate: PropTypes.string,
  Address: PropTypes.string,
  City: PropTypes.string,
  StateOrProvince: PropTypes.string,
  ZipOrPostalCode: PropTypes.string,
  County: PropTypes.string,
  Phone: PropTypes.string,
  CreatedOn: PropTypes.string,
  CurrentAsOf: PropTypes.string,
});

// planInfo represents getPlans:planInfo in Go (returned by /api/plans).
export const planInfo = PropTypes.shape({
  planID: PropTypes.string,
  planName: PropTypes.string,
  sixDegTPAID: PropTypes.number,
});

// uiPlan represents uiPlan in Go (returned by /api/report-plans).
export const uiPlan = PropTypes.shape({
  PlanID: PropTypes.string,
  PlanName: PropTypes.string,
  TPAName: PropTypes.string,
  StartDate: PropTypes.string,
  EndDate: PropTypes.string,
  FacilityMultiplier: PropTypes.number,
  ProfessionalMultiplier: PropTypes.number,
  DrugMultiplier: PropTypes.number,
  AnesthesiaMultiplier: PropTypes.number,
});

// simpleUser represents client.SimpleUser in Go (returned by /api/users/me).
export const simpleUser = PropTypes.shape({
  userID: PropTypes.string,
  fullName: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
});

// codeEntry represents codeEntry in Go (returned on success by /api/code).
export const codeEntry = PropTypes.shape({
  field: PropTypes.string,
  value: PropTypes.string,
});

// codeEntry represents []codeEntry or string in Go (returned by /api/code).
export const codeEntryOrString = PropTypes.oneOfType([
  PropTypes.arrayOf(codeEntry),
  PropTypes.string,
]);

// search represents data returned by various search actions
export const search = PropTypes.shape({
  global: PropTypes.bool,
  searchTerm: PropTypes.string,
  results: PropTypes.arrayOf(claim),
});

// sftpServer in the client loader configs
export const sftpServer = PropTypes.shape({
  privateKeyPath: PropTypes.string,
  hostKeyPath: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  serverAndPort: PropTypes.string,
  outgoingFolder: PropTypes.string,
  incomingFolder: PropTypes.string,
  reportsFolder: PropTypes.string,
  mrfFolder: PropTypes.string,
  cashPrePayFolder: PropTypes.string,
  fileTypeRestriction: PropTypes.string,
  disableSend: PropTypes.bool,
  disableReceive: PropTypes.bool,
  useNonPreferredCiphers: PropTypes.bool,
});

// repriceCodeMapping in the client loader configs
export const repriceCodeMapping = PropTypes.shape({
  element: PropTypes.string,
  code: PropTypes.string,
});

// sftpServer in the client loader configs
export const repriceCodeMappings = PropTypes.objectOf(PropTypes.arrayOf(repriceCodeMapping));

// payerOverride in the client loader configs
export const payerOverride = PropTypes.shape({
  payerID: PropTypes.string,
  repriceSegmentName: PropTypes.string,
  nameOverride: PropTypes.string,
  doubleUsage: PropTypes.bool,
});

// patientCSVConfig in the client loader configs
export const patientCSVConfig = PropTypes.shape({
  delimiter: PropTypes.string,
  header: PropTypes.bool,
  headerValues: PropTypes.string,
  dateFormat: PropTypes.string,
  maxDateFormat: PropTypes.string,
  groupNumCol: PropTypes.number,
  firstNameCol: PropTypes.number,
  lastNameCol: PropTypes.number,
  dateOfBirthCol: PropTypes.number,
  patientSSNCol: PropTypes.number,
  patientIDCol: PropTypes.number,
  patientIDFormat: PropTypes.bool,
  subscriberSSNCol: PropTypes.number,
  subscriberIDCol: PropTypes.number,
  subPlanIDCol: PropTypes.number,
  subscriberIDFormat: PropTypes.string,
  addressCols: PropTypes.arrayOf(PropTypes.number),
  cityCol: PropTypes.number,
  stateCol: PropTypes.number,
  zipcodeCol: PropTypes.number,
  countyCol: PropTypes.number,
  phoneCol: PropTypes.arrayOf(PropTypes.number),
  startDateCol: PropTypes.number,
  startDateFormat: PropTypes.string,
  terminationDateCol: PropTypes.number,
  terminationDateFormat: PropTypes.string,
  eligibleCol: PropTypes.number,
});

// patient834Config in the client loader configs
export const patient834Config = PropTypes.shape({
  subscriberSSNQual: PropTypes.string,
  subscriberIDQual: PropTypes.string,
  patientIDQual: PropTypes.string,
  familyIDQual: PropTypes.string,
  relationCodeQual: PropTypes.string,
  subPlanIDQual: PropTypes.number,
});

// configUser is a user in the config
export const configUser = PropTypes.shape({
  userID: PropTypes.string,
  fullName: PropTypes.string,
});

// changeHistory in the client loader change history
export const changeHistory = PropTypes.shape({
  user: configUser,
  diff: PropTypes.string,
  date: PropTypes.string,
});

// config represents the client loader config
export const clientConfig = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  sixDegTPAID: PropTypes.number,
  sftpServers: PropTypes.arrayOf(sftpServer),
  localIncomingFolder: PropTypes.string,
  localOutgoingFolder: PropTypes.string,
  claimsBridgeRouteFolder: PropTypes.string,
  localCashPrePayFolder: PropTypes.string,
  localReportsFolder: PropTypes.string,
  localMRFFolder: PropTypes.string,
  reportsEmails: PropTypes.string,
  subfolder999: PropTypes.string,
  mrfFileType: PropTypes.string,
  suppress999: PropTypes.bool,
  send837Invoice: PropTypes.bool,
  send837InvoiceHCP: PropTypes.bool,
  repriceSegmentName: PropTypes.string,
  repriceCodeMappings,
  payerOverrides: PropTypes.arrayOf(payerOverride),
  useHCP04Codes: PropTypes.bool,
  sendClaimHCP: PropTypes.bool,
  nteCodeOverride: PropTypes.string,
  repriceZeroOutOfNetwork: PropTypes.bool,
  cppShowValuesInReprice: PropTypes.bool,
  professionalPrefix: PropTypes.string,
  institutionalPrefix: PropTypes.string,
  extension837: PropTypes.string,
  extension999: PropTypes.string,
  extension277: PropTypes.string,
  idQualifier: PropTypes.string,
  interchangeID: PropTypes.string,
  pgpKeyPath: PropTypes.string,
  patientCSVConfig,
  patient834Config,
  changeHistory: PropTypes.arrayOf(changeHistory),
});
// File represents the File interface Web API (https://developer.mozilla.org/en-US/docs/Web/API/File)
export const File = PropTypes.shape({
  name: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.string,
  lastModified: PropTypes.number,
  lastModifiedDate: PropTypes.Date,
});

// fileSubmitStatus represents the status of file submission for the FileInput component
export const fileSubmitStatus = PropTypes.shape({
  code: PropTypes.string,
  message: PropTypes.string,
});

// used for React Virtualized
export const RenderRow = PropTypes.shape({
  index: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
});

// used for Daily Claims Report
const dailyCounts = PropTypes.shape({
  i1: PropTypes.number.isRequired,
  o1: PropTypes.number.isRequired,
  o2: PropTypes.number.isRequired,
  o3: PropTypes.number.isRequired,
  o4: PropTypes.number.isRequired,
  p1: PropTypes.number.isRequired,
  p2: PropTypes.number.isRequired,
  p3: PropTypes.number.isRequired,
  dataEntry: PropTypes.number.isRequired,
  entryValidate: PropTypes.number.isRequired,
  miscalc: PropTypes.number.isRequired,
  verify: PropTypes.number.isRequired,
  repriceServices: PropTypes.number.isRequired,
  totalServices: PropTypes.number.isRequired,
});

// used for Daily Claims Report
export const dailyClaimsRow = PropTypes.shape({
  in: dailyCounts.isRequired,
  out: dailyCounts.isRequired,
});

// used for Daily Claims Report
export const dailyClaimsProp = PropTypes.objectOf(PropTypes.objectOf(dailyClaimsRow));

// Used for PrintableReports in Settlements
export const ImpactAmounts = PropTypes.shape({
  billedAmount: PropTypes.number.isRequired,
  repricedAmount: PropTypes.number.isRequired,
  medicareAllowed: PropTypes.number.isRequired,
  additionalPayment: PropTypes.number.isRequired,
  proposedSettlementAmount: PropTypes.number.isRequired,
  medicareMultiple: PropTypes.number.isRequired,
  overTarget: PropTypes.bool,
});

export const PlanImpact = PropTypes.shape({
  currentFacility: ImpactAmounts.isRequired,
  currentProfessional: ImpactAmounts.isRequired,
  proposedFacilitySettlement: ImpactAmounts.isRequired,
  proposedProfessionalSettlement: ImpactAmounts.isRequired,
  finalFacility: ImpactAmounts.isRequired,
  finalProfessional: ImpactAmounts.isRequired,
});
